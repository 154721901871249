import moment from 'moment';
import 'moment-duration-format';
import React, {
    lazy,
    Suspense,
    useEffect,
    useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './Activities.module.scss';
import ActivitiesFilter from './activitiesFilter';
import ActivitiesTutorial from './activitiesTutorial';
import NoActivitiesText from './noActivitiesText';

import ContentContainer from '../../components/contentContainer';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';
import { useSetState } from '../../hooks';
import { useActivities, useNexus, useUnits } from '../../providers';

const ActivityCard = lazy(() => import('./activityCard'));
const RideReportExample = lazy(() => import('./rideReportExample'));

const DEFAULT_FILTERS = {
    bikeFilters: [],
    distanceFilters: [0, 200],
    durationFilters: [0, 24],
    fromDate: null,
    toDate: null,
    typeFilters: [],
};

const Activities = () => {
    const [state, setState] = useSetState(DEFAULT_FILTERS);
    const [isAtBottom, setIsAtBottom] = React.useState(false);
    const {
        bikeFilters,
        distanceFilters,
        durationFilters,
        fromDate,
        toDate,
        typeFilters,
    } = state;
    const { search } = useLocation();
    const navigate = useNavigate();
    const {
        page,
        fetch,
        hasMore,
        isFetching,
        list,
        previousFilters,
    } = useActivities();
    const { nexusUserProfile } = useNexus();
    const { convertDistanceFromSI, convertDistanceToSI } = useUnits();
    const mounted = useRef<any>();

    const getFilterParams = () => {
        if (!search) return null;

        return new URLSearchParams(search);
    };

    const fetchActivities = () => {
        const params = new URLSearchParams();

        if (toDate) {
            params.set('start_ts__lt', toDate.unix());
        }

        if (fromDate) {
            params.set('start_ts__gt', fromDate.unix());
        }

        if (durationFilters.length) {
            const [minDuration, maxDuration] = durationFilters;
            if (minDuration > 0) {
                params.append('duration__gt', (minDuration * 3600).toString());
            }

            if (maxDuration < 24) {
                params.append('duration__lt', (maxDuration * 3600).toString());
            }
        }

        if (distanceFilters.length) {
            const [minDistance, maxDistance] = distanceFilters;
            const siMinDistance = convertDistanceToSI(minDistance);
            const siMaxDistance = convertDistanceToSI(maxDistance);

            if (minDistance > 0) {
                params.append('distance__gt', siMinDistance.toString());
            }

            if (maxDistance < 200) {
                params.append('distance__lt', siMaxDistance.toString());
            }
        }

        if (typeFilters.length) {
            params.append('type', typeFilters);
        }

        if (bikeFilters.length) {
            params.append('bike', bikeFilters);
        }

        const paramString = params.toString();

        navigate(`/activities?${paramString || ''}`);

        // Only to fetch and update list when filters are available when component first renders
        if (!mounted.current) {
            if (paramString) fetch(paramString);
            mounted.current = true;
            return;
        }

        if (previousFilters === paramString) return;

        fetch(params.toString());
    };

    useEffect(fetchActivities, [state]);

    const parseFilterParams = (params : URLSearchParams) => {
        const bikes = params.get('bike');
        const filtersBike = bikes ? bikes.split(',').map((id) => id) : [];

        const types = params.get('type');
        const filtersType = types ? types.split(',') : [];

        const minDuration = params.get('duration__gt');
        const maxDuration = params.get('duration__lt');

        const minDistance = params.get('distance__gt');
        const maxDistance = params.get('distance__lt');

        const minDate = params.get('start_ts__gt');
        const maxDate = params.get('start_ts__lt');

        const convertedMinDistance = convertDistanceFromSI(Number(minDistance));
        const convertedMaxDistance = convertDistanceFromSI(Number(maxDistance));

        return {
            bikeFilters: filtersBike,
            distanceFilters: [
                Math.round(convertedMinDistance) || 0,
                Math.round(convertedMaxDistance) || 200,
            ],
            durationFilters: [
                (Number(minDuration) / 3600) || 0,
                (Number(maxDuration) / 3600) || 24,
            ],
            fromDate: minDate ? moment.unix(Number(minDate)) : null,
            toDate: maxDate ? moment.unix(Number(maxDate)) : null,
            typeFilters: filtersType,
        };
    };

    const renderExampleActivity = () => {
        if (nexusUserProfile
            && nexusUserProfile.user_journey
            && (nexusUserProfile.user_journey.activityCount || nexusUserProfile.user_journey.hasActivity)) {
            return null;
        }

        return (
            <Suspense fallback={<Spinner />}>
                <RideReportExample />
            </Suspense>
        );
    };

    const renderNoActivities = () => {
        if (list.length || isFetching) return null;

        const hasFilters = !!getFilterParams();

        if (hasFilters) {
            return (
                <div>
                    <span>
                        <Translate>FEED_NO_ACTIVITIES_FILTER</Translate>
                    </span>
                    <button
                        className={styles.clearFilters}
                        onClick={() => setState(DEFAULT_FILTERS)}
                        type="button"
                    >
                        <Translate>CLEAR</Translate>
                    </button>
                </div>
            );
        }

        return <NoActivitiesText />;
    };

    const renderTutorial = () => {
        if (nexusUserProfile && nexusUserProfile.user_journey && nexusUserProfile.user_journey.activityCount) {
            return null;
        }

        return <ActivitiesTutorial />;
    };

    useEffect(() => {
        // parsing params and applying filters
        const params = getFilterParams();

        if (params) {
            const filterValues = parseFilterParams(params);
            setState(filterValues);
        }
    }, []);

    const loadMore = () => {
        const filterParams = getFilterParams();
        fetch(filterParams?.toString());
    };

    useEffect(() => {
        const handleScroll = () => {
            if (page > 2) return;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollPosition = window.scrollY;
            const distanceFromBottom = documentHeight - windowHeight - scrollPosition;

            setIsAtBottom(distanceFromBottom < 700);
        };

        window.addEventListener('scroll', handleScroll);
        if (isAtBottom && list.length === 20 && hasMore) {
            const filterParams = getFilterParams();
            fetch(filterParams?.toString());
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAtBottom]);

    return (
        <ContentContainer style={{ paddingTop: 0 }}>
            <div className={styles.contentHeader}>
                <ActivitiesFilter
                    activeActivityFilters={typeFilters}
                    activeBikeFilters={bikeFilters}
                    applyFilters={(filters) => setState(filters)}
                    distanceFilters={distanceFilters}
                    durationFilters={durationFilters}
                    fromDate={fromDate}
                    toDate={toDate}
                />
            </div>
            <Spinner loading={isFetching} />
            {renderNoActivities()}
            {renderTutorial()}
            {renderExampleActivity()}

            {list.map((item) => (
                <Suspense key={item.id} fallback={<Spinner />}>
                    <div className={styles.activityContainer} id="cardList">
                        <ActivityCard activity={item} />
                    </div>
                </Suspense>
            ))}
            {page > 2 && hasMore && (
                <div className={styles.loadMoreContainer}>
                    <button
                        type="button"
                        className={`button ${styles.loadMoreButton}`}
                        onClick={() => loadMore()}
                    >
                        <Translate>
                            LOAD_MORE
                        </Translate>
                    </button>
                </div>
            )}
        </ContentContainer>
    );
};

export default Activities;
