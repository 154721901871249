import axios from 'axios';
import {
    ReactNode,
    useEffect,
    useMemo,
    useRef,
} from 'react';

import { useLocation } from 'react-router-dom';
import ActivitiesContext from './ActivitiesContext';
import parseActivity from './parseActivity';

import { useAuth } from '../auth';
import { useNexus } from '../nexus';

import { ZONES } from '../../assets';
import {
    ACTIVITY_TYPES,
    makeCancellable,
    RequestType,
    SERVICE_GARMIN_CONNECT,
    SERVICE_WAHOO,
    URL_BRODO,
    URL_QUARQNET_API,
    URL_STATIC_DATA,
} from '../../constants';
import { useSetState } from '../../hooks';
import Logger from '../../Logger';

function sortActivitiesByDate(a: any, b: any) {
    return b.adjustedStartTs - a.adjustedStartTs;
}

const DEFAULT_PAGE_SIZE = 20;
const URL_ACTIVITIES = `${URL_QUARQNET_API}activities/`;
const URL_ACTIVITY_SUMMARIES = `${URL_QUARQNET_API}activitysummaries/`;
const URL_ACTIVITIES_NEW = `${URL_QUARQNET_API}activities/`;

const DEFAULT_STATE = {
    activities: [],
    activityTypes: ACTIVITY_TYPES,
    hasMore: true,
    heartZones: ZONES.HEART_ZONES,
    isFetching: false,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    powerZones: ZONES.POWER_TRAINING_ZONES,
    previousFilters: '',
    totalCount: 0,
};

function ActivitiesProvider({ children }: { children: ReactNode }) {
    const [state, setState] = useSetState({ ...DEFAULT_STATE });
    const {
        activities,
        activityTypes,
        hasMore,
        page,
        pageSize,
        previousFilters,
        totalCount,
    } = state;
    const activitiesRequest = useRef< RequestType | null>(null);
    const activityDeleteRequests = useRef(new Map<string, RequestType>());
    const activityDownloadRequests = useRef(new Map<string, RequestType>());
    const activityFetchRequests = useRef(new Map<string, RequestType>());
    const activityRegenerateRequests = useRef(new Map<string, RequestType>());
    const activitySummaryUpdateRequests = useRef(new Set<RequestType>());
    const activityUpdateRequests = useRef(new Set<RequestType>());
    const fetchActivitiesTypesRequest = useRef< RequestType | null>(null);
    const fetchExampleActivityRequest = useRef< RequestType | null>(null);
    const fetchGeoCodeRequest = useRef< RequestType | null>(null);
    const fetchShockWizRequests = useRef(new Map<string, RequestType>());
    const fetchTrainingZonesRequest = useRef< RequestType | null>(null);
    const fetchWaffleBlockRequest = useRef< RequestType | null>(null);
    const uploadActivityRequests = useRef(new Set<RequestType>());
    const auth = useAuth();
    const nexus = useNexus();
    const { search } = useLocation();

    function getActivity(inId: string) {
        // eslint-disable-next-line eqeqeq
        return activities.find(({ id }: { id: string }) => id == inId);
    }

    // Reset component
    function clear() {
        if (activitiesRequest.current) {
            activitiesRequest.current.cancel();
            activitiesRequest.current = null;
        }

        activityDeleteRequests.current.forEach((request) => request.cancel());
        activityDeleteRequests.current.clear();

        activityDownloadRequests.current.forEach((request) => request.cancel());
        activityDownloadRequests.current.clear();

        activityFetchRequests.current.forEach((request) => request.cancel());
        activityFetchRequests.current.clear();

        activitySummaryUpdateRequests.current.forEach((request) => request.cancel());
        activitySummaryUpdateRequests.current.clear();

        activityRegenerateRequests.current.forEach((request) => request.cancel());
        activityRegenerateRequests.current.clear();

        activityUpdateRequests.current.forEach((request) => request.cancel());
        activityUpdateRequests.current.clear();

        uploadActivityRequests.current.forEach((request) => request.cancel());
        uploadActivityRequests.current.clear();

        if (fetchActivitiesTypesRequest.current) {
            fetchActivitiesTypesRequest.current.cancel();
        }

        if (fetchExampleActivityRequest.current) {
            fetchExampleActivityRequest.current.cancel();
        }

        if (fetchTrainingZonesRequest.current) {
            fetchTrainingZonesRequest.current.cancel();
            fetchTrainingZonesRequest.current = null;
        }

        if (fetchWaffleBlockRequest.current) {
            fetchWaffleBlockRequest.current.cancel();
            fetchWaffleBlockRequest.current = null;
        }

        if (fetchGeoCodeRequest.current) {
            fetchGeoCodeRequest.current.cancel();
            fetchGeoCodeRequest.current = null;
        }

        setState(DEFAULT_STATE);
    }

    async function downloadActivity(id: string) {
        // Check if activity file is already being fetched
        const currentRequest = activityDownloadRequests.current.get(id);
        if (currentRequest) {
            try {
                await currentRequest.promise;

                return null;
            } catch (error) {
                return null;
            }
        }

        const downloadRequest = makeCancellable(axios.get(`${URL_ACTIVITIES}${id}/file/`, { responseType: 'blob' }));
        activityDownloadRequests.current.set(id, downloadRequest);

        try {
            const { data } = await downloadRequest.promise;
            const fileUrl = window.URL.createObjectURL(data);

            activityDownloadRequests.current.delete(id);

            return fileUrl;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching activity file', id, error);
                // Remove the activity file request from the list
                activityDownloadRequests.current.delete(id);
            }

            return null;
        }
    }

    async function fetchActivities(filters?: string, pageCount?: number) {
        const filterString = filters ? filters.toString() : '';
        const isNewFilters = (filterString !== previousFilters);
        const currentPageCount = pageCount || DEFAULT_PAGE_SIZE;

        if (activitiesRequest.current) {
            activitiesRequest.current.cancel();
        }

        let fetchPage = page;

        if (isNewFilters || pageSize !== currentPageCount) {
            fetchPage = 1;
            setState({
                page: 1,
                previousFilters: filterString,
            });
        } else if (!hasMore) {
            return null;
        }

        setState({ isFetching: true });

        activitiesRequest.current = makeCancellable(axios.get(`${URL_ACTIVITIES_NEW}?${filterString}`, {
            params: {
                page: fetchPage,
                page_size: pageCount || DEFAULT_PAGE_SIZE,
            },
        }));

        try {
            const response = await activitiesRequest.current.promise;
            activitiesRequest.current = null;

            const parsedActivities = response.data.map(parseActivity);

            setState({
                activities: fetchPage === 1
                    ? [...parsedActivities]
                    : [
                        ...isNewFilters
                            ? []
                            : activities.filter(({ id }: { id: string }) => (!parsedActivities.find(
                                (activity: any) => activity.id === id,
                            ))),
                        ...parsedActivities,
                    ].sort(sortActivitiesByDate),
                hasMore: (parsedActivities.length >= currentPageCount),
                isFetching: false,
                page: fetchPage + 1,
                pageSize: currentPageCount,
                totalCount: parseInt(response.headers['total-count']),
            });

            return parsedActivities;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching activities', fetchPage, error);
                activitiesRequest.current = null;
                setState({ hasMore: false, isFetching: false });
            }

            return null;
        }
    }

    async function fetchActivity(id: string, useExisting = false) {
        if (useExisting) {
            // Check if the activity exists
            const activity = getActivity(id);

            if (activity) {
                return activity;
            }
        }

        // Check if activity is already being fetched
        const currentRequest = activityFetchRequests.current.get(id);
        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return parseActivity(data);
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.get(`${URL_ACTIVITIES_NEW}${id}/`));
        activityFetchRequests.current.set(id, fetchRequest);
        try {
            const { data } = await fetchRequest.promise;
            // Remove the activity request from the list
            activityFetchRequests.current.delete(id);

            const parsedActivity = parseActivity(data);

            setState({
                activities: [
                    // Ensure no duplicates get added
                    // eslint-disable-next-line eqeqeq
                    ...activities.filter((itrActivity: any) => itrActivity.id != id),
                    parsedActivity,
                ].sort(sortActivitiesByDate),
            });
            return parsedActivity;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching activity', id, error);
                // Remove the activity request from the list
                activityFetchRequests.current.delete(id);
            }

            return null;
        }
    }

    async function fetchExampleActivity() {
        if (fetchExampleActivityRequest.current) {
            try {
                const { data } = await fetchExampleActivityRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchExampleActivityRequest.current = makeCancellable(
            axios.get(`${URL_STATIC_DATA}exampleactivities/mtb/activity.json`),
        );

        try {
            const { data } = await fetchExampleActivityRequest.current.promise;

            fetchExampleActivityRequest.current = null;

            return parseActivity(data);
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching example activity', error);
                fetchExampleActivityRequest.current = null;
            }

            return null;
        }
    }

    async function fetchTrainingZones() {
        if (fetchTrainingZonesRequest.current) {
            try {
                const { data } = await fetchTrainingZonesRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        fetchTrainingZonesRequest.current = makeCancellable(
            axios.get(`${URL_STATIC_DATA}training_zones.json`),
        );

        try {
            const { data } = await fetchTrainingZonesRequest.current.promise;
            fetchTrainingZonesRequest.current = null;

            setState({
                heartZones: data.HEART_ZONES,
                powerZones: data.POWER_TRAINING_ZONES,
            });

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching zones data', error);
                fetchTrainingZonesRequest.current = null;
            }

            return null;
        }
    }

    async function fetchShockWiz(id: string) {
        const currentRequest = fetchShockWizRequests.current.get(id);

        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.get(`${URL_ACTIVITIES}${id}/swrecommend/`));
        fetchShockWizRequests.current.set(id, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;

            fetchShockWizRequests.current.delete(id);

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching shockWiz', id, error);
                fetchShockWizRequests.current.delete(id);
            }

            return null;
        }
    }

    async function fetchGeoCode(lat: string, lng: string) {
        if (!lat || !lng) {
            return null;
        }

        if (fetchGeoCodeRequest.current) {
            try {
                const { data } = await fetchGeoCodeRequest.current.promise;

                return data;
            } catch (error) {
                return null;
            }
        }

        // eslint-disable-next-line max-len
        fetchGeoCodeRequest.current = makeCancellable(axios.get(`${URL_QUARQNET_API}geocode/`, { params: { lat, lng } }));

        try {
            const { data } = await fetchGeoCodeRequest.current.promise;

            fetchGeoCodeRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching geo code', error);
                fetchGeoCodeRequest.current = null;
            }

            return null;
        }
    }

    async function deleteActivity(id: string) {
        // Check if activity is already being fetched
        const currentRequest = activityDeleteRequests.current.get(id);
        if (currentRequest) {
            try {
                await currentRequest.promise;

                return true;
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(axios.delete(`${URL_QUARQNET_API}activities/${id}/`));
        activityDeleteRequests.current.set(id, fetchRequest);

        try {
            await fetchRequest.promise;
            // Remove the activity request from the list
            activityDeleteRequests.current.delete(id);

            setState({
                // eslint-disable-next-line eqeqeq
                activities: activities.filter((itrActivity: any) => itrActivity.id != id),
                totalCount: totalCount - 1,
            });

            return true;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error deleting activity', id, error);
                // Remove the activity request from the list
                activityDeleteRequests.current.delete(id);
            }

            return null;
        }
    }

    async function regenerateActivity(id: string) {
        if (!id) {
            return null;
        }

        const currentRequest = activityRegenerateRequests.current.get(id);
        if (currentRequest) {
            try {
                const { data } = await currentRequest.promise;

                return parseActivity(data);
            } catch (error) {
                return null;
            }
        }

        const fetchRequest = makeCancellable(
            axios.patch(`${URL_ACTIVITIES_NEW}${id}/`, { recalc: 1 }),
        );

        activityRegenerateRequests.current.set(id, fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            // Remove the finished promise from the map
            activityRegenerateRequests.current.delete(id);

            const parsedActivity = parseActivity(data);

            setState({
                // Override the activity with the updated activity
                activities: activities
                    .map((activity: any) => ((activity.id === id) ? parsedActivity : activity))
                    .sort(sortActivitiesByDate),
            });

            return parsedActivity;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error regenerating activity', id, error);

                // Remove the finished promise from the map
                activityRegenerateRequests.current.delete(id);
            }

            return null;
        }
    }

    async function triggerGarminSync() {
        const linkedGarmin = nexus.nexusLinkedIds.find(
            ({ service }: { service: string }) => service === SERVICE_GARMIN_CONNECT,
        );

        if (!linkedGarmin) return false;

        try {
            await axios.post(
                `${URL_BRODO}v2/backfill`,
                { linked_id: linkedGarmin.linked_id, service: SERVICE_GARMIN_CONNECT },
            );

            return true;
        } catch (error) {
            Logger.error('Garmin Sync Error', error);
            return false;
        }
    }

    async function triggerWahooSync() {
        if (!nexus.nexusLinkedIds.some(({ service }: { service: string }) => service === SERVICE_WAHOO)) {
            return false;
        }

        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

        try {
            await axios.post(
                `${URL_BRODO}thirdparty/poll`,
                `source=${SERVICE_WAHOO}`,
                { headers },
            );

            return true;
        } catch (error) {
            Logger.warn('Wahoo Sync Error', error);
            return false;
        }
    }

    async function updateActivity(id: string, activityUpdates: string) {
        let nextActivity = null;
        let previousActivity = null;
        // Get activity to be updated
        const activityToUpdate = activities.find((activity: any) => activity.id === id);
        // If activity to update exists locally
        if (activityToUpdate) {
            // Preserve next and previous activity ids
            nextActivity = activityToUpdate.next_activity_id;
            previousActivity = activityToUpdate.prev_activity_id;
        }

        const fetchRequest = makeCancellable(
            axios.patch(`${URL_ACTIVITIES_NEW}${id}/`, activityUpdates),
        );

        activityUpdateRequests.current.add(fetchRequest);
        try {
            const { data } = await fetchRequest.promise;
            // Remove the finished promise from the set
            activityUpdateRequests.current.delete(fetchRequest);

            const parsedActivity = parseActivity(data);

            // Restore next and previous activity ids to new version
            parsedActivity.next_activity_id = nextActivity;
            parsedActivity.prev_activity_id = previousActivity;

            setState({
                // Override the activity with the updated activity
                activities: activities
                    .map((activity:any) => ((activity.id === id) ? parsedActivity : activity))
                    .sort(sortActivitiesByDate),
            });

            return parsedActivity;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error updating activity', id, error);

                // Remove the finished promise from the set
                activityUpdateRequests.current.delete(fetchRequest);
            }

            return null;
        }
    }

    async function updateActivitySummary(id: string, activitySummaryId: string, activitySummaryUpdates: any) {
        const fetchRequest = makeCancellable(
            axios.patch(`${URL_ACTIVITY_SUMMARIES}${activitySummaryId}/`, { ...activitySummaryUpdates }),
        );

        activitySummaryUpdateRequests.current.add(fetchRequest);

        try {
            const { data } = await fetchRequest.promise;
            // Remove the finished promise from the set
            activitySummaryUpdateRequests.current.delete(fetchRequest);

            // update activity summary data
            const updatedActivities = activities.map((activity: any) => ((activity.id === id)
                ? parseActivity({
                    ...activity,
                    activitysummary_set: [
                        data,
                        ...activity.activitysummary_set.filter((_activitySummary: any, index: number) => index),
                    ],
                })
                : activity
            )).sort(sortActivitiesByDate);

            setState({ activities: updatedActivities });

            // eslint-disable-next-line eqeqeq
            return updatedActivities.find((activity: any) => activity.id == id);
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error updating activity summary', activitySummaryId, error);

                // Remove the finished promise from the set
                activitySummaryUpdateRequests.current.delete(fetchRequest);
            }

            return null;
        }
    }

    async function uploadActivity(file: any, inOptions: any = null) {
        const options = {
            ...inOptions,
            headers: {
                'Content-Type': 'application/octet-stream',
                ...(inOptions ? inOptions.headers : {}),
            },
        };

        const activityForm = new FormData();

        activityForm.append('file', file);

        const uploadRequest = makeCancellable(axios.post(`${URL_BRODO}manual/fit`, activityForm, options));

        uploadActivityRequests.current.add(uploadRequest);

        try {
            const { status } = await uploadRequest.promise;
            uploadActivityRequests.current.delete(uploadRequest);

            return (status === 200 || status === 202);
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error uploading activity', file.name, error);
                uploadActivityRequests.current.delete(uploadRequest);
            }

            return false;
        }
    }

    async function fetchActivitiesTypes() {
        if (fetchActivitiesTypesRequest.current) {
            try {
                const { data } = await fetchActivitiesTypesRequest.current.promise;
                return data;
            } catch (error) {
                return null;
            }
        }

        fetchActivitiesTypesRequest.current = makeCancellable(
            axios.get(`${URL_QUARQNET_API}activitytypes/`),
        );

        try {
            const { data } = await fetchActivitiesTypesRequest.current.promise;
            fetchActivitiesTypesRequest.current = null;

            if (data) {
                const newTypes = [...activityTypes];

                const activityTypesValues = activityTypes.map((type: any) => type.value);

                data.forEach((activityType: any) => {
                    if (!activityTypesValues.includes(activityType)) {
                        newTypes.push({ label: activityType, value: activityType });
                    }
                });

                setState({ activityTypes: newTypes });
            }

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                Logger.warn('Error fetching activities types', error);
                fetchActivitiesTypesRequest.current = null;
            }

            return null;
        }
    }

    function onAuthenticationChange() {
        if (!auth.accessToken) {
            clear();
            return;
        }

        // Handling fetch activities at the component level when search params exists. Avoids unnecessary fetches.
        if (!search) fetchActivities();
        fetchActivitiesTypes();
        fetchTrainingZones();
    }

    useEffect(() => {
        onAuthenticationChange();
    }, [auth.accessToken]);

    useEffect(() => () => {
        clear();
    }, []);

    const context = useMemo(() => ({
        activities: {
            ...state,
            clear: () => clear(),
            delete: (id: string) => deleteActivity(id),
            download: (id: string) => downloadActivity(id),
            // Fetches a page of activities
            fetch: (filters?: string, pageCount?: number) => fetchActivities(filters, pageCount),
            // Fetches personalized activities types list for filter options
            fetchActivitiesTypes,
            // Fetches a specific activity
            fetchActivity,
            fetchExampleActivity,
            fetchGeoCode,
            fetchShockWiz,
            // Fetches activity zone calculations,
            fetchTrainingZones,
            list: activities,
            regenerateActivity,
            triggerGarminSync,
            triggerWahooSync,
            updateActivity,
            updateActivitySummary,
            uploadActivity,
        },
    }), [state]);

    return (
        <ActivitiesContext.Provider value={context}>
            {children}
        </ActivitiesContext.Provider>
    );
}

export default ActivitiesProvider;
