import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './ActivitiesTable.module.scss';
import ActivitiesTableHeader from './activitiesTableHeader';
import ActivitiesTableRow from './activitiesTableRow';

import NoActivitiesText from '../noActivitiesText';

import EditActivityModal from '../../activity/editActivityModal';
import DeleteActivityModal from '../../activity/deleteActivityModal';

import Spinner from '../../../components/spinner';
import Translate, { useTranslation } from '../../../components/translate';
import { makeCancellable } from '../../../constants';
import { useActivities, useUnits } from '../../../providers';
import { useSetState } from '../../../hooks';

export interface ActivityUpdates {
    name?: string;
    type?: string;
    public?: boolean;
    bike_uuid?: string;
}

const MAX_DISTANCE = 200;
const MAX_DURATION = 24;
const MIN_DISTANCE = 0;
const MIN_DURATION = 0;
const ROWS_PER_PAGE = 20;

const DEFAULT_STATE = {
    bikeFilters: [],
    currentPage: 1,
    distanceFilters: [0, 200],
    durationFilters: [0, 24],
    fromDate: null,
    isDeleting: false,
    isUpdating: false,
    ordering: null,
    searchTerm: '',
    selectedActivity: null,
    showDeleteActivity: false,
    showEditActivityModal: false,
    toDate: null,
    typeFilters: [],
};

const ActivitiesTable = () => {
    const [state, setState] = useSetState(DEFAULT_STATE);
    const activities = useActivities();
    const units = useUnits();
    const { search } = useLocation();
    const navigate = useNavigate();
    const translate = useTranslation();
    const { convertDistanceFromSI } = units;
    const {
        bikeFilters,
        currentPage,
        distanceFilters,
        durationFilters,
        fromDate,
        isDeleting,
        isUpdating,
        ordering,
        searchTerm,
        selectedActivity,
        showDeleteActivity,
        showEditActivityModal,
        toDate,
        typeFilters,
    } = state;

    const deleteActivityRequest = useRef<any>(null);
    const fetchActivitiesRequest = useRef<any>(null);
    const updateActivityRequests = useRef(new Map());
    const mounted = useRef<any>();

    const setCurrentPage = () => {
        if (activities.list.length > 0 && activities.list.length <= ROWS_PER_PAGE) {
            setState({ currentPage: 1 });
        }
    };

    useEffect(setCurrentPage, [activities.list]);

    const getFilterParams = () => {
        if (!search) return '';

        return new URLSearchParams(search);
    };

    const parseFilterParams = (params: URLSearchParams) => {
        const bikes = params.get('bike');
        const newBikeFilters = bikes ? bikes.split(',').map((id) => id) : [];

        const types = params.get('type');
        const newTypeFilters = types ? types.split(',') : [];

        const minDuration = params.get('duration__gt');
        const maxDuration = params.get('duration__lt');

        const minDistance = params.get('distance__gt');
        const maxDistance = params.get('distance__lt');

        const minDate = params.get('start_ts__gt');
        const maxDate = params.get('start_ts__lt');

        const newOrdering = params.get('ordering');

        const newSearchTerm = params.get('name') || '';

        const convertedMinDistance = convertDistanceFromSI(Number(minDistance));
        const convertedMaxDistance = convertDistanceFromSI(Number(maxDistance));

        return {
            bikeFilters: newBikeFilters,
            distanceFilters: [
                Math.round(convertedMinDistance) || 0,
                Math.round(convertedMaxDistance) || 200,
            ],
            durationFilters: [
                (Number(minDuration) / 3600) || 0,
                (Number(maxDuration) / 3600) || 24,
            ],
            fromDate: minDate ? moment.unix(Number(minDate)) : null,
            ordering: newOrdering,
            searchTerm: newSearchTerm,
            toDate: maxDate ? moment.unix(Number(maxDate)) : null,
            typeFilters: newTypeFilters,
        };
    };

    const deleteActivity = async () => {
        if (!selectedActivity) return null;

        if (deleteActivityRequest.current) {
            try {
                const succeeded = await deleteActivityRequest.current.promise;

                return succeeded;
            } catch (error) {
                return null;
            }
        }

        setState({ isDeleting: true });

        deleteActivityRequest.current = makeCancellable(activities.delete(selectedActivity));

        try {
            const succeeded = await deleteActivityRequest.current.promise;

            deleteActivityRequest.current = null;

            setState({ isDeleting: false, selectedActivity: null });

            return succeeded;
        } catch (error: any) {
            if (!error.isCancelled) {
                deleteActivityRequest.current = null;

                setState({ isDeleting: false, selectedActivity: null });
            }
        }

        return null;
    };

    const fetchActivities = () => {
        const params = new URLSearchParams();

        if (toDate) {
            params.set('start_ts__lt', toDate.unix());
        }

        if (fromDate) {
            params.set('start_ts__gt', fromDate.unix());
        }

        if (durationFilters.length) {
            const [minDuration, maxDuration] = durationFilters;

            if (minDuration > 0) {
                params.append('duration__gt', (minDuration * 3600).toString());
            }

            if (maxDuration < 24) {
                params.append('duration__lt', (maxDuration * 3600).toString());
            }
        }

        if (distanceFilters.length) {
            const [minDistance, maxDistance] = distanceFilters;

            const siMinDistance = units.convertDistanceToSI(minDistance);
            const siMaxDistance = units.convertDistanceToSI(maxDistance);

            if (minDistance > 0) {
                params.append('distance__gt', siMinDistance.toString());
            }

            if (maxDistance < 200) {
                params.append('distance__lt', siMaxDistance.toString());
            }
        }

        if (typeFilters.length) {
            params.append('type', typeFilters);
        }

        if (bikeFilters.length) {
            params.append('bike', bikeFilters);
        }

        if (ordering) {
            params.append('ordering', ordering);
        }

        if (searchTerm) {
            params.append('name', searchTerm);
        }

        const paramString = params.toString();

        navigate(`/activities/log?${paramString || ''}`);

        if (!mounted.current) {
            if (paramString) activities.fetch(paramString);
            mounted.current = true;
            return;
        }

        if (activities.previousFilters === paramString) return;

        activities.fetch(params.toString(), ROWS_PER_PAGE);
    };

    const loadMore = async () => {
        if (fetchActivitiesRequest.current) {
            try {
                await fetchActivitiesRequest.current.promise;
            } catch (error) {
                return;
            }
        }

        fetchActivitiesRequest.current = makeCancellable(
            activities.fetch(getFilterParams().toString(), ROWS_PER_PAGE),
        );

        try {
            const data = await fetchActivitiesRequest.current.promise;
            fetchActivitiesRequest.current = null;

            if (!data) return;

            setState({ currentPage: currentPage + 1 });
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchActivitiesRequest.current = null;
            }
        }
    };

    const nextPage = () => {
        if (fetchActivitiesRequest.current) return;

        if (currentPage < (activities.list.length / ROWS_PER_PAGE)) {
            setState({ currentPage: currentPage + 1 });

            return;
        }

        if (activities.hasMore) {
            loadMore();
        }
    };

    const updateActivity = async (activityUpdates: ActivityUpdates) => {
        const activity = activities.list.find(({ id }) => id === selectedActivity);

        if (!activity) return null;

        setState({ isUpdating: true });

        const fetchRequest = makeCancellable(
            activities.updateActivity(activity.id, activityUpdates),
        );

        updateActivityRequests.current.set(activity.id, fetchRequest);

        try {
            const updatedActivity = await fetchRequest.promise;

            updateActivityRequests.current.delete(activity.id);

            if (!updateActivityRequests.current.size) {
                setState({ isUpdating: false });
            }

            return updatedActivity;
        } catch (error: any) {
            if (!error.isCancelled) {
                updateActivityRequests.current.delete(activity.id);

                if (!updateActivityRequests.current.size) {
                    setState({ isUpdating: false });
                }
            }

            return null;
        }
    };

    const handleSort = (name: string) => setState({ ordering: ordering === name ? `-${name}` : name });

    useEffect(
        fetchActivities,
        [
            ordering,
            typeFilters,
            distanceFilters,
            durationFilters,
            bikeFilters,
            fromDate,
            toDate,
            searchTerm,
        ],
    );

    useEffect(() => {
        const params = getFilterParams();

        if (params) {
            const filterValues = parseFilterParams(params);
            setState(filterValues);
        }

        return () => {
            if (deleteActivityRequest.current) {
                deleteActivityRequest.current.cancel();
                deleteActivityRequest.current = null;
            }

            if (fetchActivitiesRequest.current) {
                fetchActivitiesRequest.current.cancel();
                fetchActivitiesRequest.current = null;
            }

            updateActivityRequests.current.forEach((request) => request.cancel());
            updateActivityRequests.current.clear();
        };
    }, []);

    const renderActivities = () => activities.list
        .slice(currentPage === 1 ? 0 : ((currentPage - 1) * ROWS_PER_PAGE), (currentPage * ROWS_PER_PAGE))
        .map((activity) => (
            <ActivitiesTableRow
                activity={activity}
                key={activity.id}
                onDelete={(id) => setState({ selectedActivity: id, showDeleteActivity: true })}
                onEdit={(id) => setState({ selectedActivity: id, showEditActivityModal: true })}
            />
        ));

    const renderDeleteActivityModal = () => {
        if (!showDeleteActivity) return null;

        return (
            <DeleteActivityModal
                deleteActivity={() => deleteActivity()}
                onCancel={() => setState({ selectedActivity: null, showDeleteActivity: !showDeleteActivity })}
                onDelete={() => setState({ selectedActivity: null, showDeleteActivity: !showDeleteActivity })}
                open={showDeleteActivity}
            />
        );
    };

    const renderEditActivityModal = () => {
        if (!showEditActivityModal) return null;

        return (
            <EditActivityModal
                activity={activities.list.find(({ id }) => id === selectedActivity)}
                activityTypes={activities.activityTypes}
                onSave={() => setState({ selectedActivity: null, showEditActivityModal: !showEditActivityModal })}
                onCancel={() => setState(
                    { selectedActivity: null, showEditActivityModal: !showEditActivityModal },
                )}
                open={showEditActivityModal}
                showShareActivity
                updateActivity={(updates) => updateActivity(updates)}
            />
        );
    };

    const renderHeader = () => (
        <div className={styles.headerContainer}>
            <div className={styles.header}>
                <div className={styles.title}>
                    <Translate>ACTIVITY_LOG</Translate>
                </div>
                {!!activities.list.length && (
                    <input
                        className={styles.searchBox}
                        onChange={(event) => setState({ searchTerm: event.target.value })}
                        onKeyDown={(event) => {
                            const { key } = event;

                            if (key !== 'Enter') return;

                            fetchActivities();
                        }}
                        placeholder={translate('SEARCH')}
                        type="text"
                        value={searchTerm}
                    />
                )}
            </div>
            <div className={styles.clearFilter}>
                {search && (
                    <button
                        className={styles.clearFiltersButton}
                        onClick={() => setState({
                            bikeFilters: [],
                            distanceFilters: [MIN_DISTANCE, MAX_DISTANCE],
                            durationFilters: [MIN_DURATION, MAX_DURATION],
                            fromDate: null,
                            ordering: null,
                            searchTerm: '',
                            toDate: null,
                            typeFilters: [],
                        })}
                        type="button"
                    >
                        <CancelIcon className={styles.clearButtonIcon} />
                        <Translate>CLEAR_ALL_FILTERS</Translate>
                    </button>
                )}
            </div>
        </div>
    );

    const renderPagination = () => {
        const activitiesCount = activities.totalCount;
        let startCount = currentPage === 1 ? currentPage : ((currentPage - 1) * ROWS_PER_PAGE) + 1;

        if (!activitiesCount) startCount = 0;

        return (
            <div className={styles.paginationContainer}>
                <div className={styles.pageCount}>
                    <strong>
                        {startCount}
                        &nbsp; - &nbsp;
                        {((currentPage * ROWS_PER_PAGE < activitiesCount)
                            ? (currentPage * ROWS_PER_PAGE)
                            : activitiesCount
                        )}
                    </strong>
                    &nbsp;
                    <Translate>OF</Translate>
                    &nbsp;
                    {activitiesCount}
                </div>
                <div className={styles.arrowButtons}>
                    <button
                        disabled={currentPage === 1}
                        className={styles.buttonArrow}
                        onClick={() => setState({ currentPage: currentPage - 1 })}
                        type="button"
                    >
                        <ArrowLeftIcon fontSize="large" />
                    </button>
                    <button
                        disabled={(((currentPage * ROWS_PER_PAGE) > activitiesCount)
                            || (((currentPage * ROWS_PER_PAGE) === activitiesCount) && !activities.hasMore)
                        )}
                        className={styles.buttonArrow}
                        onClick={() => nextPage()}
                        type="button"
                    >
                        <ArrowRightIcon fontSize="large" />
                    </button>
                </div>
            </div>
        );
    };

    const renderTable = () => (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <ActivitiesTableHeader
                    activeActivityFilters={typeFilters}
                    activeBikeFilters={bikeFilters}
                    activityTypes={activities.activityTypes}
                    applyFilters={(params) => setState(params)}
                    distanceFilters={distanceFilters}
                    durationFilters={durationFilters}
                    fromDate={fromDate}
                    onSort={(key) => handleSort(key)}
                    toDate={toDate}
                />
                <tbody>
                    {renderActivities()}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className={styles.container}>
            <Spinner loading={activities.isFetching || isDeleting || isUpdating} />
            {renderHeader()}
            {!activities.list.length ? <NoActivitiesText /> : (
                <>
                    {renderTable()}
                    {renderPagination()}
                    {renderDeleteActivityModal()}
                    {renderEditActivityModal()}
                </>
            )}
        </div>
    );
};

export default ActivitiesTable;
