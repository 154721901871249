import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './AxsFeedDropdown.module.scss';
import MenuItem from './menuItem';
import MenuItemButton from './menuItemButton';
import NavBarDropdown from './navBarDropdown';

import Translate from '../../components/translate';
import WaffleBlock from '../../components/waffleBlock';
import { SRAM_RED, WHITE } from '../../constants';
import { useActivities } from '../../providers';

interface AxsFeedDropdownProps {
    onAuxClick: () => void,
    onClick: () => void,
    open?: boolean,
}

const AxsFeedDropdown = ({
    onAuxClick,
    onClick,
    open,
}: AxsFeedDropdownProps) => {
    const location = useLocation();
    const { previousFilters } = useActivities();

    const activitesUrlString = previousFilters ? `/activities?${previousFilters}` : '/activities';
    const activitiesLogUrlString = previousFilters ? `/activities/log?${previousFilters}` : '/activities/log';

    return (
        <NavBarDropdown
            id="data-test-axs-feed-dropdown"
            buttonClassName={styles.buttonHover}
            containerStyle={{ padding: '0 1rem' }}
            dropdownStyle={{ left: '50%', transform: 'translateX(-50%)' }}
            label={(
                <div className={styles.axsMenuIconContainer}>
                    <MenuOutlinedIcon fontSize="large" style={{ color: WHITE }} />
                </div>
            )}
            onAuxClick={onAuxClick}
            onClick={onClick}
            open={open}
        >
            <MenuItem className="flex">
                <MenuItemButton
                    id="data-test-axs-ride-report"
                    onClick={onClick}
                    style={(location.pathname === '/activities') ? { color: SRAM_RED } : {}}
                    to={activitesUrlString}
                >
                    <Translate>AXS_RIDE_REPORT</Translate>
                </MenuItemButton>
            </MenuItem>
            <WaffleBlock flag="test" showIndicator>
                <MenuItem className="flex">
                    <MenuItemButton
                        onClick={onClick}
                        style={(location.pathname === '/activities/log') ? { color: SRAM_RED } : {}}
                        to={activitiesLogUrlString}
                    >
                        <Translate>ACTIVITY_LOG</Translate>
                    </MenuItemButton>
                </MenuItem>
            </WaffleBlock>
            <MenuItem className="flex">
                <MenuItemButton
                    className={styles.menuItemButton}
                    onClick={onClick}
                    style={(location.pathname === '/activities/upload') ? { color: SRAM_RED } : {}}
                    to="/activities/upload"
                >
                    <div>
                        <Translate>ACTIVITY_UPLOAD</Translate>
                    </div>
                    <span className={styles.plus}>
                        +
                    </span>
                </MenuItemButton>
            </MenuItem>
            <MenuItem className="flex">
                <MenuItemButton
                    className={styles.menuItemButton}
                    onClick={onClick}
                    style={(location.pathname === '/guides/tire/pressure') ? { color: SRAM_RED } : {}}
                    to="/guides/tire/pressure"
                >
                    <div>
                        <Translate>TIRE_PRESSURE_GUIDE</Translate>
                    </div>
                </MenuItemButton>
            </MenuItem>
            <MenuItem className="flex">
                <MenuItemButton
                    className={styles.menuItemButton}
                    onClick={onClick}
                    style={(location.pathname === '/guides/fullmount/chain/calculator')
                        ? { color: SRAM_RED } : {}}
                    to="/guides/fullmount/chain/calculator"
                >
                    <div>
                        <Translate>FULL_MOUNT_CHAIN_CALCULATOR</Translate>
                    </div>
                </MenuItemButton>
            </MenuItem>
        </NavBarDropdown>
    );
};

export default AxsFeedDropdown;
